import type Alpine from "alpinejs"

import { isNil, Nil } from "~/src/lib/any"

type RouteFn = (hash: string) => boolean
type RouteType = string | RegExp | RouteFn | Nil

const STORE_KEY = "hashrouter"
const STORE_KEY_SCROLL_TO = `${STORE_KEY}.scroll-to`

/**
 * Defines a `$route` Alpine magic method that accepts a route and returns true if
 * that route matches the hash fragment of the window location, false otherwise.
 * When a hash navigation occurs, the page scrolls to the top of the page unless
 * the `scroll-to` modifier is used. See examples below.
 *
 * Routes can be:
 * - a string, which is matched literally
 * - a regex
 * - a predicate function taking the hash fragment string as its argument
 * - nothing, which returns true when the hash fragment string is empty
 *
 * @example
 * <div x-show="$route("home")>
 *   Welcome home
 * </div>
 *
 * @example
 * <div x-show="$route(/product_id_\d+/)">
 *   View product
 * </div>
 *
 * @example
 * <div x-show="$route((fragment) => ["foo", "bar", "baz"].includes(fragment))">
 *  ...
 * </div>
 *
 * @example
 * <div x-show="$route()">
 *   Start here
 * </div>
 *
 * You can also use the `scroll-to` modifier to scroll to the element when the route matches:
 *
 * @example
 * <div x-hashrouter.scroll-to></div> <!-- Scrolls to this element when hashchange occurs -->
 */
export default function (alpine: typeof Alpine) {
  alpine.store(STORE_KEY, window.location.hash.substring(1))

  window.addEventListener("hashchange", () => {
    alpine.store(STORE_KEY, window.location.hash.substring(1))

    requestAnimationFrame(() => {
      if (alpine.store(STORE_KEY_SCROLL_TO)) {
        const el = alpine.store(STORE_KEY_SCROLL_TO)

        if (el instanceof HTMLElement) {
          el.scrollIntoView()
        }
      } else {
        window.scrollTo(0, 0)
      }
    })
  })

  alpine.directive(STORE_KEY, (el, { modifiers }) => {
    if (modifiers.includes("scroll-to")) {
      alpine.store(STORE_KEY_SCROLL_TO, el)
    }
  })

  alpine.magic("route", () => {
    return (route: RouteType) => {
      const hash = alpine.store(STORE_KEY)?.toString() ?? ""
      if (typeof route == "string") {
        return route == hash
      }
      if (route instanceof RegExp) {
        return route.test(hash)
      }
      if (route instanceof Function) {
        return route(hash)
      }

      return hash.trim() == "" && isNil(route)
    }
  })
}
